import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Footer from "./Components/Footer/Footer";
import Navbar from "./Components/Navbar/Navbar";
import Home from "./Components/Home";
import CreditosPersonales from "./Components/RequestCredit/CreditosPersonales";
import CreditosEmpresariales from "./Components/RequestCredit/CreditosEmpresariales";
import Arrendamiento from "./Components/RequestCredit/Arrendamiento";
import { useEffect, useState } from "react";
import ScrollToTop from "./Components/ScrollToTop/ScrollToTop";
import LoadingScreen from "./Components/LoadingScreen/LoadingScreen";
import AvisoPrivacidad from "./Components/AvisoPrivacidad/AvisoPrivacidad";
import UnidadEspecializada from "./Components/UnidadEspecializada/UnidadEspecializada";
import Nosotros from "./Components/Nosotros/Nosotros";
import BuroEntidadesFinancieras from "./Components/BuroEntidadesFinancieras/BuroEntidadesFinancieras";
import DeleteAccount from "./Components/DeleteAccount/DeleteAccount";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Navbar />
      <Routes>
        <Route path="/" element={<HomeWithLoading />} />
        <Route path="/nosotros" element={<Nosotros />} />
        <Route path="/eliminar-cuenta" element={<DeleteAccount />} />
        <Route path="/creditos-personales" element={<CreditosPersonales />} />
        <Route path="/arrendamiento" element={<Arrendamiento />} />
        <Route
          path="/creditos-empresariales"
          element={<CreditosEmpresariales />}
        />
        <Route path="/aviso-de-privacidad" element={<AvisoPrivacidad />} />
        <Route path="/une" element={<UnidadEspecializada />} />
        <Route
          path="/buro-entidades-financieras"
          element={<BuroEntidadesFinancieras />}
        />
      </Routes>
      <Footer />
    </Router>
  );
}

function HomeWithLoading() {
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/") {
      const visited = localStorage.getItem("hasVisitedHome");
      if (visited) {
        setLoading(false); //FALSE
      } else {
        const timer = setTimeout(() => {
          setLoading(false);
          localStorage.setItem("hasVisitedHome", "true");
        }, 2500);
        return () => clearTimeout(timer);
      }
    } else {
      setLoading(false);
    }
  }, [location]);

  return loading ? <LoadingScreen /> : <Home />;
}

export default App;
