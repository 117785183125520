import React from "react";
import emailjs from "emailjs-com";
import "./DeleteAccount.css";
// import HeroBannerImage from "../HeroBanner/HeroBannerImage";

const SERVICE_ID = "service_a2cbb1p";
const TEMPLATE_ID = "template_233xqbp";
const PUBLIC_KEY = "_Y9DU6sng0tYqrLNC";

const DeleteAccount = () => {
  const handleOnSubmit = (e) => {
    e.preventDefault();
    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, PUBLIC_KEY).then(
      (result) => {
        console.log(result.text);
        alert("Message Sent Successfully");
      },
      (error) => {
        console.log(error.text);
        alert("Something went wrong!");
      }
    );
    e.target.reset();
  };

  return (
    <>
      <br /><br /><br />
      <div
        style={{
          textAlign: "center",
          margin: "25px",
        }}
      >
        <h1>Eliminar Cuenta</h1>
        <p>
          Si deseas eliminar tu cuenta de la aplicación de El de Fiar, por
          favor, completa el siguiente formulario, envía tu solicitud y en menos
          de 24 horas, tu cuenta será eliminada Ten en cuenta que esta acción
          será permanente y no podrás recuperar tus datos una vez completada. Al
          confirmar la eliminación de tu cuenta, perderás acceso a todas las
          funcionalidades y servicios asociados con tu perfil.
        </p>
      </div>
      <div className="contact-contain">
        <div className="form-container">
          <div>
            <form onSubmit={handleOnSubmit}>
              <input
                type="text"
                id="from_name"
                name="from_name"
                placeholder="Nombre Completo"
                required
              />

              <input
                type="email"
                id="from_email"
                name="from_email"
                placeholder="Correo Electrónico"
                required
              />
              <textarea
                id="from_textarea"
                name="from_textarea"
                placeholder="Motivo de eliminación de cuenta (opcional)"
              ></textarea>

              <button type="submit" className="btn">
                Eliminar Cuenta
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeleteAccount;
